import { Grid } from "theme-ui"
import {
  ArchiveLink,
  BlankLink,
  FirstPageLink,
  LastPageLink,
  NextPageLink,
  PrevPageLink
} from "../links"

import * as sass from "./pagination.module.scss"

const PrevStoryLinks = ({ first, prev }) => {
  return (
    <Grid
      variant={"pageLinksPrev"}
      sx={{
        gridTemplateColumns: "repeat(2, auto)"
      }}
    >
      <FirstPageLink link={first} /> <PrevPageLink link={prev} story={true} />
      <span id="firstLabel" sx={{ variant: "text.hidden" }}>
        go to the start of the story
      </span>
      <span id="prevLabel" sx={{ variant: "text.hidden" }}>
        previous page
      </span>
    </Grid>
  )
}

const NextStoryLinks = ({ last, next }) => {
  return (
    <Grid
      variant={"pageLinksNext"}
      sx={{
        gridTemplateColumns: "repeat(2, auto)"
      }}
    >
      <NextPageLink link={next} story={true} /> <LastPageLink link={last} />
      <span id="lastLabel" sx={{ variant: "text.hidden" }}>
        go to the latest existing scene
      </span>
      <span id="nextLabel" sx={{ variant: "text.hidden" }}>
        next page
      </span>
    </Grid>
  )
}

export const ScenePagination = ({ chapter, prev, next, first, last }) => {
  return (
    <Grid
      as="nav"
      variant={"pagination"}
      className={sass.pageNav}
      sx={{
        gridTemplateColumns: "repeat(4, 1fr)"
      }}
    >
      {prev ? <PrevStoryLinks prev={prev} first={first} /> : <BlankLink />}
      <ArchiveLink
        link="/story/"
        label="t.o.c."
        aria="return to the table of contents"
      />
      <ArchiveLink
        link={chapter}
        label="chapter"
        aria="return to chapter title page"
      />
      {next ? <NextStoryLinks next={next} last={last} /> : <BlankLink />}
    </Grid>
  )
}

export const ChapterPagination = ({ prev, next, first, last }) => {
  return (
    <Grid
      as="nav"
      variant={"pagination"}
      className={sass.pageNav}
      sx={{
        gridTemplateColumns: "repeat(3, 1fr)"
      }}
    >
      {prev ? <PrevStoryLinks prev={prev} first={first} /> : <BlankLink />}
      <ArchiveLink
        link="/story/"
        label="t.o.c."
        aria="return to the table of contents"
      />
      {next ? <NextStoryLinks next={next} last={last} /> : <BlankLink />}
    </Grid>
  )
}
