import { graphql } from "gatsby"
import { kebabCase } from "lodash"

import { Article, Section } from "../components/containers"
import { ArchiveItem } from "../components/blog"
import { TaggedArchivePagination } from "../components/pagination"
import { TagPageTitle } from "../components/headings"
import Seo from "../components/Seo"

const TagTemplate = ({
  data: {
    allBlogPost: { edges: posts, totalCount }
  },
  pageContext: { tag, currentPage, numTagPages },
  location
}) => {
  const tagLink = kebabCase(tag)
  const isFirst = currentPage === 1
  const isLast = currentPage === numTagPages
  const prevPage = isFirst
    ? null
    : currentPage === 2
    ? `/blog/tagged/${tagLink}/`
    : `/blog/tagged/${tagLink}/${currentPage - 1}/`
  const nextPage = isLast ? null : `/blog/tagged/${tagLink}/${currentPage + 1}/`
  const pageTitle = `#${tag}`

  return (
    <>
      <Seo
        pageTitle={pageTitle}
        pageDescription={`All posts tagged ${pageTitle}`}
        pageUrl={location.pathname}
      />
      <Article>
        <TagPageTitle
          number={`${totalCount} post${totalCount === 1 ? "" : "s"}`}
          title={pageTitle}
        />
        <Section padding={true} semantic={false}>
          {posts.map(({ node: post }) => (
            <ArchiveItem
              key={post.id}
              slug={post.slug}
              title={post.title}
              date={post.date}
              author={post.author}
            />
          ))}
        </Section>
        <Section padding={true} heading="Tag Listing Pagination">
          <TaggedArchivePagination prev={prevPage} next={nextPage} />
        </Section>
      </Article>
    </>
  )
}

export default TagTemplate

export const tagTemplateQuery = graphql`
  query TagTemplateQuery($tag: String, $skip: Int!, $limit: Int!) {
    allBlogPost(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
      filter: { tags: { in: [$tag] } }
    ) {
      totalCount
      edges {
        node {
          id
          slug
          author
          title
          date
          summary
          tags
        }
      }
    }
  }
`
