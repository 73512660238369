import { Grid } from "theme-ui"
import { ArchiveLink, BlankLink, NextPageLink, PrevPageLink } from "../links"

import * as sass from "./pagination.module.scss"

const PrevBlogLink = ({ prev }) => {
  return (
    <Grid
      variant={"pageLinksPrev"}
      sx={{
        gridTemplateColumns: "auto"
      }}
    >
      <PrevPageLink link={prev} story={false} />
      <span id="prevLabel" sx={{ variant: "text.hidden" }}>
        previous post
      </span>
    </Grid>
  )
}

const NextBlogLink = ({ next }) => {
  return (
    <Grid
      variant={"pageLinksNext"}
      sx={{
        gridTemplateColumns: "auto"
      }}
    >
      <NextPageLink link={next} story={false} />
      <span id="nextLabel" sx={{ variant: "text.hidden" }}>
        next post
      </span>
    </Grid>
  )
}

const BlogPagination = ({ prev, next }) => {
  return (
    <Grid
      as="nav"
      variant={"pagination"}
      className={sass.pageNav}
      sx={{
        gridTemplateColumns: "repeat(3, 1fr)"
      }}
    >
      {prev ? <PrevBlogLink prev={prev} /> : <BlankLink />}
      <ArchiveLink
        link="/blog/"
        label="archive"
        aria="return to blog archive"
      />
      {next ? <NextBlogLink next={next} /> : <BlankLink />}
    </Grid>
  )
}

export default BlogPagination
