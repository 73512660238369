import { Grid } from "theme-ui"
import { BlankLink, NextPageLink, ArchiveLink, PrevPageLink } from "../links"

const PrevArchiveLink = ({ prev }) => {
  return (
    <Grid
      variant={"pageLinksPrev"}
      sx={{
        gridTemplateColumns: "auto"
      }}
    >
      <PrevPageLink link={prev} story={true} />
      <span id="prevLabel" sx={{ variant: "text.hidden" }}>
        go to previous page
      </span>
    </Grid>
  )
}

const NextArchiveLink = ({ next }) => {
  return (
    <Grid
      variant={"pageLinksNext"}
      sx={{
        gridTemplateColumns: "auto"
      }}
    >
      <NextPageLink link={next} story={true} />
      <span id="nextLabel" sx={{ variant: "text.hidden" }}>
        go to next page
      </span>
    </Grid>
  )
}

export const ArchivePagination = ({ prev, next }) => {
  return (
    <Grid
      as="nav"
      role="navigation"
      aria-label="Blog Archive"
      sx={{ gridTemplateColumns: "repeat(2, 1fr)" }}
    >
      {prev ? <PrevArchiveLink prev={prev} /> : <BlankLink />}
      {next ? <NextArchiveLink next={next} /> : <BlankLink />}
    </Grid>
  )
}

export const TaggedArchivePagination = ({ prev, next }) => {
  return (
    <Grid
      as="nav"
      role="navigation"
      aria-label="Tag Listing"
      sx={{ gridTemplateColumns: "repeat(3, 1fr)" }}
    >
      {prev ? <PrevArchiveLink prev={prev} /> : <BlankLink />}
      <ArchiveLink
        link="/blog/tagged/"
        label="all tags"
        aria="return to list of all tags"
      />
      {next ? <NextArchiveLink next={next} /> : <BlankLink />}
    </Grid>
  )
}
